import React, { useState, useEffect, useRef } from 'react';
import Player from './Spaceship';
import Obstacle from './Obstacle';
import TimeFreeze from './TimeFreeze';
import BlackWhole from './Blackwhole';
import Evolve from './Evolve';
import Points from './Points';
import './game.css';

const Game = ({ exit, highscore, onSaveHighScore }) => {
  const [playerPosition, setPlayerPosition] = useState({ x: 850, y: 900 });
  const [obstacles, setObstacles] = useState([]);
  const [specialItems, setSpecialItems] = useState([]);
  const [isGameOver, setIsGameOver] = useState(false);
  const [score, setScore] = useState(0);
  const [speedMultiplier, setSpeedMultiplier] = useState(1);
  const [soundEnabled, setSoundEnabled] = useState(true);
  const [freeze, setFreeze] = useState(false);
  const [blackout, setBlackout] = useState(false);
  const gameAreaRef = useRef(null);
  const playerWidth = window.innerWidth < 600 ? 40 : 80;
  const playerHeight = window.innerWidth < 600 ? 40 : 80;
  const obstacleWidth = window.innerWidth < 600 ? 40 : 80;
  const obstacleHeight = window.innerWidth < 600 ? 40 : 80;

  useEffect(() => {
    const handleMouseMove = (event) => {
      const rect = gameAreaRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left - 40;
      const y = event.clientY - rect.top - 40;
      setPlayerPosition({ x, y });
    };

    const handleTouchMove = (event) => {
      const rect = gameAreaRef.current.getBoundingClientRect();
      const touch = event.touches[0];
      const x = touch.clientX - rect.left - 40;
      const y = touch.clientY - rect.top - 40;
      setPlayerPosition({ x, y });
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('touchmove', handleTouchMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  useEffect(() => {
    if (isGameOver) return;

    const interval = setInterval(() => {
      setScore((prevScore) => prevScore + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [isGameOver]);

  useEffect(() => {
    if (isGameOver) return;

    const interval = setInterval(() => {
      const x = Math.random() * (gameAreaRef.current.clientWidth - obstacleWidth);
      const type = Math.floor(Math.random() * 13); // Obstacle types
      const speed = (1 + Math.random() * 2) * 0.1 * speedMultiplier; // Reduced speed
      const y = 0;
      const newObstacle = { x, y, type, speed };
      setObstacles((prev) => [...prev, newObstacle]);
    }, 1000);

    return () => clearInterval(interval);
  }, [isGameOver, speedMultiplier]);

  useEffect(() => {
    if (isGameOver) return;

    const interval = setInterval(() => {
      const x = Math.random() * (gameAreaRef.current.clientWidth - obstacleWidth);
      const type = Math.floor(Math.random() * 4) + 10; // Special item types
      const speed = (1 + Math.random() * 2) * 0.1 * speedMultiplier; // Reduced speed
      const y = 0;
      const newSpecialItem = { x, y, type, speed };
      setSpecialItems((prev) => [...prev, newSpecialItem]);
    }, 5000);

    return () => clearInterval(interval);
  }, [isGameOver, speedMultiplier]);

  useEffect(() => {
    if (isGameOver) return;

    const updateInterval = setInterval(() => {
      setObstacles((prev) =>
        prev
          .map((obstacle) => ({
            ...obstacle,
            y: obstacle.y + (freeze ? 3 : 5), // update y position by 5 pixels every 16ms
          }))
          .filter((obstacle) => obstacle.y < gameAreaRef.current.clientHeight)
      );

      setSpecialItems((prev) =>
        prev
          .map((item) => ({
            ...item,
            y: item.y + (freeze ? 3 : 5), // update y position by 5 pixels every 16ms
          }))
          .filter((item) => item.y < gameAreaRef.current.clientHeight)
      );

      const checkCollision = () => {
        setObstacles((prevObstacles) => {
          const newObstacles = prevObstacles.filter((obstacle) => {
            const distanceX = Math.abs(obstacle.x - playerPosition.x - playerWidth / 2);
            const distanceY = Math.abs(obstacle.y - playerPosition.y - playerHeight / 2);

            if (
              distanceX <= (obstacleWidth + playerWidth) / 2 &&
              distanceY <= (obstacleHeight + playerHeight) / 2
            ) {
              setIsGameOver(true);
              return false; // Remove obstacle
            }
            return true;
          });
          return newObstacles;
        });

        setSpecialItems((prevSpecialItems) => {
          const newSpecialItems = prevSpecialItems.filter((specialItem) => {
            const distanceX = Math.abs(specialItem.x - playerPosition.x - playerWidth / 2);
            const distanceY = Math.abs(specialItem.y - playerPosition.y - playerHeight / 2);

            if (
              distanceX <= (obstacleWidth + playerWidth) / 2 &&
              distanceY <= (obstacleHeight + playerHeight) / 2
            ) {
              let scoreToAdd = 0;
              if (specialItem.type === 10) { // Evolve
                setObstacles([]);
                scoreToAdd = 60;
              } else if (specialItem.type === 11) { // TimeFreeze
                setFreeze(true);
                setTimeout(() => setFreeze(false), 5000);
              } else if (specialItem.type === 12) { // BlackWhole
                setBlackout(true);
                setTimeout(() => setBlackout(false), 5000);
              } else if (specialItem.type === 13) { // Points
                scoreToAdd = 20;
              }

              setScore((prevScore) => prevScore + scoreToAdd);
              return false; // Remove special item
            }
            return true;
          });
          return newSpecialItems;
        });
      };

      checkCollision();
    }, 6); // update every 16ms (60 times per second)

    return () => clearInterval(updateInterval);
  }, [obstacles, specialItems, playerPosition, isGameOver, freeze]);

  useEffect(() => {
    if (score > 0 && score % 40 === 0) {
      setSpeedMultiplier((prevMultiplier) => prevMultiplier + 0.5);
    }
  }, [score]);

  const handleRestart = () => {
    setIsGameOver(false);
    setScore(0);
    setObstacles([]);
    setSpecialItems([]);
    setPlayerPosition({ x: 850, y: 900 });
    setSpeedMultiplier(1);
    setSoundEnabled(true);
  };

  const handleGameOver = () => {
    const newHighScore = score;
    onSaveHighScore(newHighScore);
    setSoundEnabled(false);
  };

  return (
    <div className="game-container">
      <div className="scoreboard">Score: {score}</div>
      <div className="game-area" ref={gameAreaRef}>
        <Player x={playerPosition.x} y={playerPosition.y} soundEnabled={soundEnabled} />
        {obstacles.map((obstacle, index) => (
          <Obstacle key={index} x={obstacle.x} y={obstacle.y} type={obstacle.type} />
        ))}
        {specialItems.map((item, index) => {
          switch (item.type) {
            case 10:
              return <Evolve key={index} x={item.x} y={item.y} />;
            case 11:
              return <TimeFreeze key={index} x={item.x} y={item.y} />;
            case 12:
              return <BlackWhole key={index} x={item.x} y={item.y} />;
            case 13:
              return <Points key={index} x={item.x} y={item.y} />;
            default:
              return null;
          }
        })}
        {isGameOver && (
          <div className="game-over" onFocus={handleGameOver}>
            <span>Game Over</span>
            <button onClick={handleRestart}>Restart</button>
            <button onClick={exit}>Exit</button>
          </div>
        )}
        {blackout && <div className="blackout-overlay" />}
      </div>
    </div>
  );
};

export default Game;
