
import './App.css';
import userImage from './user.png';
import News from './news.png';
import GameZone from './GameZone';
import { useState } from 'react';
import { auth, db } from './FirebaseConfig';
import { AuthProvider } from './AuthContext';
// Adjust the import according to your file structure
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { doc, setDoc,collection } from "firebase/firestore";
import { useUser, UserProvider } from './UserContext'; 
import AdBlockDetector from './AdBlockDetector';

function App() {
  const [isLoginVisible, setIsLoginVisible] = useState(false);
  const showLogin = () => {
    setIsLoginVisible(true);
  };

  const hideLogin = () => {
    setIsLoginVisible(false);
  };

  return (
    <div className="App">
      <h1>Emotions</h1>
      <p>An endless adventure through the Emotions</p>
      <span className="melder">
        <div className="loger" onClick={showLogin}><img alt="Login" src={userImage} /></div>
      </span>
      <UserProvider>
  <AuthProvider>
    <GameZone />
  </AuthProvider>
  {isLoginVisible && <Loger hideLogin={hideLogin} />}
</UserProvider>
    </div>
  );
}
function Loger({ hideLogin }) {
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const { setUser } = useUser();

  const toggleCreateAccount = () => {
    setIsCreatingAccount(!isCreatingAccount);
  };

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleUsernameChange = (e) => setUsername(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let userCredential;
      if (isCreatingAccount) {
        userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        const userRef = doc(db, 'users', user.uid);
        await setDoc(userRef, { email, username });
      } else {
        userCredential = await signInWithEmailAndPassword(auth, email, password);
      }
      const user = userCredential.user;
      setUser({ email: user.email, username: user.displayName || username });
      hideLogin();
    } catch (error) {
      console.error('Error with authentication:', error);
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const userCredential = await signInWithPopup(auth, provider);
      const user = userCredential.user;
      const userRef = doc(db, 'users', user.uid);
      await setDoc(userRef, { email: user.email, username: user.displayName });
      setUser({ email: user.email, username: user.displayName });
      hideLogin();
    } catch (error) {
      console.error('Error with Google sign-in:', error);
    }
  };

  return (
    <div className="loginOptions" id="logop">
      <div className="login-content">
        {isCreatingAccount ? (
          <>
            <h2>Create Account</h2>
            <form onSubmit={handleSubmit}>
              <input type="text" placeholder="Username" value={username} onChange={handleUsernameChange} required />
              <input type="email" placeholder="Email" value={email} onChange={handleEmailChange} required />
              <input type="password" placeholder="Password" value={password} onChange={handlePasswordChange} required />
              <button type="submit" className="logger">Create Account</button>
            </form>
            <p>
              Already have an account? <button onClick={toggleCreateAccount} className="link-button">Login here</button>
            </p>
          </>
        ) : (
          <>
            <h2>Login</h2>
            <form onSubmit={handleSubmit}>
              <input type="email" placeholder="Email" value={email} onChange={handleEmailChange} required />
              <input type="password" placeholder="Password" value={password} onChange={handlePasswordChange} required />
              <button type="submit" className="logger">Login</button>
            </form>
            <p>
              Don't have an account? <button onClick={toggleCreateAccount} className="link-button">Create one</button>
            </p>
          </>
        )}
        <div className="social-login">
          <button className="btn google" onClick={handleGoogleLogin}>Login with Google</button>
        </div>
        <button onClick={hideLogin} className="close-button">Close</button>
      </div>
    </div>
  );
}
export default App;
