import React from 'react';
import Blackwhole from './gameObtacles/blackwhole.jpg';

const BlackWhole = ({ x, y }) => {
  return (
    <img alt="blackwhole" src={Blackwhole} style={{ height: 30, width: 30, position: 'absolute', left: x, top: y }} />
  );
};

export default BlackWhole;
