import React from 'react';
import PointsIcon from './gameObtacles/points.png';

const Points = ({ x, y }) => {
  return (
    <img alt="points" src={PointsIcon} style={{ height: 30, width: 30, position: 'absolute', left: x, top: y }} />
  );
};

export default Points;
