import React from 'react';
import Super from './gameObtacles/super.png';

const Evolve = ({ x, y }) => {
  return (
    <img alt="evolve" src={Super} style={{ height: 30, width: 30, position: 'absolute', left: x, top: y }} />
  );
};

export default Evolve;

