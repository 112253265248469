import React, { useEffect, useRef } from 'react';
import enemy1 from './gameObtacles/enemy1.gif';
import enemy2 from './gameObtacles/pusher.gif';
import enemy3 from './gameObtacles/destroyer.gif';
import enemy4 from './gameObtacles/sickness.gif';
import enemy5 from './gameObtacles/insidejob.gif';
import enemy6 from './gameObtacles/1.png';
import enemy7 from './gameObtacles/2.png';
import enemy8 from './gameObtacles/3.png';
import enemy9 from './gameObtacles/4.png';
import enemy10 from './gameObtacles/5.png';
import enemy11 from './gameObtacles/6.png';
import enemy12 from './gameObtacles/7.png';
import enemy13 from './gameObtacles/8.png';

const soundEffects = [
  new Audio(require('./sounds/enemy1.mp3')), // replace with your sound effect file
  new Audio(require('./sounds/enemy2.mp3')),
  new Audio(require('./sounds/enemy3.mp3')),
  new Audio(require('./sounds/enemy4.mp3')),
  new Audio(require('./sounds/enemy5.mp3')),
  new Audio(require('./sounds/enemy6.mp3')),
  new Audio(require('./sounds/enemy7.mp3')),
  new Audio(require('./sounds/enemy8.mp3')),
  new Audio(require('./sounds/enemy9.mp3')),
  new Audio(require('./sounds/enemy10.mp3')),
  new Audio(require('./sounds/enemy11.mp3')),
  new Audio(require('./sounds/enemy12.mp3')),
  new Audio(require('./sounds/enemy13.mp3')),
];

const Obstacle = ({ x, y, type }) => {
  const images = [
    enemy1, enemy2, enemy3, enemy4, enemy5,
    enemy6, enemy7, enemy8, enemy9, enemy10,
    enemy11, enemy12, enemy13
  ];
  const obstacleImg = images[type];
  const soundEffect = soundEffects[type];
  const obstacleSize = window.innerWidth < 600 ? 40 : 80;

  useEffect(() => {
    const playSound = () => {
      if (soundEffect && typeof soundEffect.play === 'function') {
        soundEffect.volume = 0.3; // Set volume
        soundEffect.play().catch(error => {
          console.error('Error playing sound:', error);
        });
      }
    };

    playSound();
  }, [soundEffect]);

  return (
    <img
      src={obstacleImg}
      alt="Obstacle"
      style={{
        position: 'absolute',
        left: `${x}px`,
        top: `${y}px`,
        width: `${obstacleSize}px`,
        height: `${obstacleSize}px`,
        pointerEvents: 'none'
      }}
    />
  );
};

export default Obstacle;
