import React from 'react';
import Time from './gameObtacles/time.png';

const TimeFreeze = ({ x, y }) => {
  return (
    <img alt="time-freeze" src={Time} style={{ height: 30, width: 30, position: 'absolute', left: x, top: y }} />
  );
};

export default TimeFreeze;
