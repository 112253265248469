// GameZone.js
import React, { useState, useEffect , useRef} from 'react';
import './game.css';
import { useAuth } from './AuthContext';
import { db } from './FirebaseConfig'; // Adjust the import according to your file structure
import { collection, getDocs, query, orderBy, limit, doc, getDoc,setDoc } from 'firebase/firestore';
import Game from './Game';

function GameZone() {
  const [view, setView] = useState('gameOptions');
  const { currentUser } = useAuth();
  const [userHighScore, setUserHighScore] = useState(null);
  const [globalHighScores, setGlobalHighScores] = useState([]);

  useEffect(() => {
    if (currentUser) {
      const fetchUserHighScore = async () => {
        const userRef = doc(db, 'users', currentUser.uid, 'highscore', 'details');
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setUserHighScore(userDoc.data().score);
        }
      };

      const fetchGlobalHighScores = async () => {
        const highScoresRef = collection(db, 'users');
        const highScoresQuery = query(highScoresRef, orderBy('highscore', 'desc'), limit(10));
        const highScoresSnapshot = await getDocs(highScoresQuery);
        const scores = highScoresSnapshot.docs.map(doc => ({
          username: doc.data().username,
          score: doc.data().highscore
        }));
        setGlobalHighScores(scores);
      };

      fetchUserHighScore();
      fetchGlobalHighScores();
    }
  }, [currentUser]);

  const handleViewChange = (newView) => {
    setView(newView);
  };

  useEffect(() => {
    if (currentUser && userHighScore !== null) {
      const userRef = doc(db, 'users', currentUser.uid, 'highscore', 'details');
      setDoc(userRef, { score: userHighScore });
    }
  }, [currentUser, userHighScore]);

  return (
    <div className='controll'>
      {view === 'gameOptions' && <GameOptions onChangeView={handleViewChange} />}
      {view === 'start' && <StartScreen onExit={() => handleViewChange('gameOptions')} highscore={userHighScore} setUserHighScore={setUserHighScore} />}
      {view === 'options' && <Options onExit={() => handleViewChange('gameOptions')} />}
      {view === 'highScore' && currentUser && (
        <HighScores
          userHighScore={userHighScore}
          globalHighScores={globalHighScores}
          onExit={() => handleViewChange('gameOptions')}
        />
      )}
      {view === 'highScore' && !currentUser && <LoginPrompt onExit={() => handleViewChange('gameOptions')} />}
      <div className='infos'>
        <p>
        In the thrilling game Emotions, players must navigate through a vibrant and unpredictable world filled with various emotions. The objective is simple yet challenging: avoid getting hit by these emotions as they come at you from all directions. Each emotion has its own unique behavior and pattern, making every moment a test of your reflexes and strategy. Can you stay calm under pressure and dodge anger, sadness, joy, and fear to reach the highest score? Dive into Emotions and see how well you can manage the unpredictable tides of feelings!
        <ins className="adsbygoogle"
     style={{display:'block'}}
     data-ad-layout="in-article"
     data-ad-format="fluid"
     data-ad-client="ca-pub-1896847981971583"
     data-ad-slot="9923670327"></ins>
        </p>
      </div>
      <div className="add3">
      </div>
    </div>
  );
}

function GameOptions({ onChangeView }) {
  return (
    <span className='getti'>
      <button className='btn1 btn-primary' onClick={() => onChangeView('start')}>Start</button>
      <button className='btn2 btn-primary' onClick={() => onChangeView('options')}>Options</button>
      <button className='btn3 btn-primary' onClick={() => onChangeView('highScore')}>High Score</button>
    </span>
  );
}

function StartScreen({ onExit,highscore,setUserHighScore }) {
  return (
    <div className='full-screen1'>
      <Game  exit={onExit} highscore={highscore}  onSaveHighScore={(newHighScore) => {
    if (newHighScore > highscore) {
      setUserHighScore(newHighScore);
    }
  }}/>
    </div>
  );
}

function Options({ onExit }) {
  const { currentUser } = useAuth();
  const [difficulty, setDifficulty] = useState('easy');
  const [gameMode, setGameMode] = useState('singleplayer');

  useEffect(() => {
    if (currentUser) {
      const fetchOptions = async () => {
        const userOptionsRef = doc(db, 'users', currentUser.uid, 'options', 'settings');
        const docSnap = await getDoc(userOptionsRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setDifficulty(data.difficulty);
          setGameMode(data.gameMode);
        }
      };
      fetchOptions();
    }
  }, [currentUser]);

  const handleSaveOptions = async () => {
    if (currentUser) {
      const userOptionsRef = doc(db, 'users', currentUser.uid, 'options', 'settings');
      await setDoc(userOptionsRef, { difficulty, gameMode });
      alert('Options saved successfully!');
    } else {
      alert('You need to be logged in to save options');
    }
  };

  return (
    <div className='full-screen'>
      <h2>Options</h2>
      <label>
        Difficulty Level:
        <select value={difficulty} onChange={(e) => setDifficulty(e.target.value)}>
          <option value="easy">Easy</option>
          <option value="medium">Medium</option>
          <option value="hard">Hard</option>
        </select>
      </label>
      <label>
        Game Mode:
        <select value={gameMode} onChange={(e) => setGameMode(e.target.value)}>
          <option value="singleplayer">Single Player</option>
          <option value="multiplayer">Multiplayer</option>
        </select>
      </label>
      <button className='facebook' onClick={handleSaveOptions}>Save Options</button>
      <button className='btn4' onClick={onExit}>Exit</button>
    </div>
  );
}

function HighScores({ userHighScore, globalHighScores, onExit }) {
  return (
    <div className='full-screen'>
      <h2>High Scores</h2>
      <div>
        <h3>Your High Score</h3>
        <p>{userHighScore !== null ? userHighScore : 'No high score available'}</p>
      </div>
      <div>
        <h3>Global High Scores</h3>
        <ul>
          {globalHighScores.map((score, index) => (
            <li key={index}>{score.username}: {score.score}</li>
          ))}
        </ul>
      </div>
      <button className='btn4' onClick={onExit}>Exit</button>
    </div>
  );
}

function LoginPrompt({ onExit }) {
  return (
    <div className='full-screen'>
      <h2>Please Log In to View High Scores</h2>
      <button className='btn4' onClick={onExit}>Back to Menu</button>
      {/* Add login functionality here */}
    </div>
  );
}




export default GameZone;
